import React from "react";
import clsx from "clsx";

import { Testimonial, Carousel } from "@molecules";
import { Container } from "@atoms";

const TestimonialGrid = ({ testimonials, className: _className }) => {
  return (
    <section className={clsx("my-10 sm:my-20", _className)}>
      <Container padding>
        <div className="mt-4">
          <div className="hidden grid-cols-1 gap-8 md:grid md:grid-cols-3">
            {testimonials?.map(testimonial => {
              return (
                <div key={testimonial.uid}>
                  <Testimonial {...testimonial} stretch />
                </div>
              );
            })}
          </div>
          <div className="relative block md:hidden">
            <Carousel maxVisible={1} indicators>
              {testimonials?.map(testimonial => {
                return (
                  <div key={testimonial.uid} className="h-full">
                    <Testimonial {...testimonial} stretch />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </Container>
    </section>
  );
};

TestimonialGrid.defaultProps = {};

export default TestimonialGrid;
